<template>
  <div class="vuepal-local-tasks">
    <ul>
      <li
        v-for="task in tasks"
        :key="task.baseId"
        :data-route-name="getRouteName(task)"
      >
        <nuxt-link v-if="isNuxtRouted(task)" :to="task.url.path">{{
          task.title
        }}</nuxt-link>
        <a v-else :href="task.url.path">{{ task.title }}</a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
export type LocalTaskFragment = {
  baseId: string
  active: boolean
  title: string
  url:
    | { __typename: 'DefaultEntityUrl'; routeName: string; path: string }
    | { __typename: 'DefaultInternalUrl'; routeName: string; path: string }
    | { __typename: 'EntityCanonicalUrl'; routeName: string; path: string }
    | { __typename: 'ExternalUrl'; path: string }
    | { __typename: 'RedirectUrl'; path: string }
}

const route = useRoute()

// A key to make sure the data is reloaded when the route changes.
const key = computed(() => {
  return route.path
})

const { data: tasks } = await useLazyAsyncData<LocalTaskFragment[]>(
  'localTasks:' + key.value,
  () =>
    useGraphqlQuery('localTasks', { path: route.path }).then((v) => {
      if (v.data.route && 'localTasks' in v.data.route) {
        return v.data.route.localTasks || []
      }

      return []
    }),
  { watch: [key] },
)

function isNuxtRouted(task: LocalTaskFragment): boolean {
  return (
    task.url.__typename === 'EntityCanonicalUrl' &&
    task.url.routeName === 'entity.node.canonical'
  )
}

function getRouteName(task: LocalTaskFragment) {
  const url = task.url
  return url && 'routeName' in url ? url.routeName : ''
}
</script>

<style lang="scss">
.vuepal-local-tasks > ul {
  display: flex;
  gap: 1rem;
  a {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 43px;
    font-weight: bold;
    border-radius: 10rem;
    font-family: sans-serif;
    font-size: 15px;
    &.router-link-active,
    &:hover {
      background: #d9e0f9;
      color: #043cad;
    }
  }
}
</style>
